/*공통*/
html {
	font-size: clamp(12px, calc(16 * (100vw / 1024)), 16px);
}
/*공통영역*/
header.desktop {
	width: 87.5rem;
	height: 4.563rem;
	margin-left: auto;
	margin-right: auto;
	/* position: fixed; */
	z-index: 100;
	display: flex;
	justify-content: space-between;
	line-height: 4.563rem;
	/* top:0; */
	background-color: #FFFFFF;
	border-bottom: 1px solid #EBEBEB;
}
header .main_title{
	font-weight: 700;
	font-size: 1.25rem;
	color: #000000;
	cursor: pointer;
}
header .login_area button{
	font-weight: 500;
	font-size: 0.875rem;
	color: #909090;
	margin-left: 0.9375rem;
}
header.desktop .menu_area{
	display: flex;
	width: 75%;
}
header.desktop .menu_area button{
	font-weight: 400;
	font-size: 0.9375rem;
	color: #C1C1C1;
	margin-right: 0.9375rem;
}
.content_wrap{
	width: 87.5rem;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
}
.content_wrap .sub_title{
	width: 100%;
	color: #191919;
	font-weight: 500;
	font-size: 1.375rem;
	margin-top: 1.25rem;
	align-items: center;
}
footer .copyright {
	display: flex;
	color: #D8D8D8;
	font-weight: 500;
	font-size: 0.75rem;
	text-align: center;
	margin-top: 5rem;
	/* padding:80px 0 40px; */
	/* width:100%; */
	/* letter-spacing: 0; */
}
footer .copyright .personal_info {
	margin-left: 1.25rem;
	cursor: pointer;
	text-decoration: underline;
}
/* 테스트 */
.wrap1 {
	max-width: 100%; 
    /* margin: 50px auto 0;  */
}
.video1 {
	position: relative;
    width: 100%;
    height: 0; 
    padding-bottom: 56.25%; 
}
.video1 iframe {
	position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%; 
}
/* 테스트 끝 */
/* 홈 */
.content_wrap .home_logo{
	width: 32.63rem;
	height: 31.38rem;
	margin-top: 3.125rem;
}
.content_wrap .home_logo_text{
	font-weight: 500;
	font-size: 2rem;
	color: #000000;
	text-align: center;
	margin-bottom: 3.125rem;
}
/* 홈 끝 */
/* 로그인 */
.content_wrap .login_content_area{
	width: 27.5rem;
	margin-top: 12.5rem;
	margin-bottom: 3.125rem;
}
.content_wrap .login_content_area .content_title{
	color: #000000;
	font-weight: 500;
	font-size: 1rem;
	margin-bottom: 1.563rem;
}
.content_wrap .login_content_area input{
	width: 100%;
    font-size: 1.125rem;
	margin-bottom: 1.875rem;
	padding-bottom: 0.625rem;
	border-bottom: #C1C1C1 1px solid; /* 초기에 사용할 색상 (예: 회색) */
	outline: none; /* 클릭 시 포커스 효과 제거 */
	transition: border-bottom-color 0.3s ease; /* 색상 변화에 대한 전환 효과 */
}
.content_wrap .login_content_area input:focus{
	border-bottom: #11167B 1.5px solid; /* 클릭 시 색상 변경 (예: 검은색) */
}
.content_wrap .login_content_area button{
	width: 100%;
	height: 4rem;
	background-color: #11167B;
	border-radius: 3.125rem;
	color: #FFFFFF;
	font-weight: 500;
	font-size: 1.25rem;
	margin-top: 3.125rem;
}
/* 로그인 끝 */
/* 회원가입 */
.content_wrap .signup_content_area{
	width: 27.5rem;
	margin-top: 3.125rem;
}
.content_wrap .signup_content_area .content_title{
	color: #000000;
	font-weight: 500;
	font-size: 1rem;
	margin-bottom: 1.563rem;
}
.content_wrap .signup_content_area .id_area{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 0.3125rem;
}
.content_wrap .signup_content_area .id_area input{
	width: 19.44rem;
    font-size: 1.125rem;
	padding-bottom: 0.625rem;
	border-bottom: #C1C1C1 1px solid; /* 초기에 사용할 색상 (예: 회색) */
	outline: none; /* 클릭 시 포커스 효과 제거 */
	transition: border-bottom-color 0.3s ease; /* 색상 변화에 대한 전환 효과 */
}
.content_wrap .signup_content_area .id_area button{
	width: 7.125rem;
	height: 4rem;
	background-color: #11167B;
	border-radius: 3.125rem;
	color: #FFFFFF;
	font-weight: 500;
	font-size: 1.25rem;
}
.content_wrap .signup_content_area .email_chk{
	color: #3AA0FF;
	font-weight: 400;
	font-size: 0.875rem;
	margin-bottom: 1.875rem;
}
.content_wrap .signup_content_area input.input_long{
	width: 100%;
    font-size: 1.125rem;
	margin-bottom: 1.875rem;
	padding-bottom: 0.625rem;
	border-bottom: #C1C1C1 1px solid; /* 초기에 사용할 색상 (예: 회색) */
	outline: none; /* 클릭 시 포커스 효과 제거 */
	transition: border-bottom-color 0.3s ease; /* 색상 변화에 대한 전환 효과 */
}
.content_wrap .signup_content_area input:focus{
	border-bottom: #11167B 1.5px solid; /* 클릭 시 색상 변경 (예: 검은색) */
}
.content_wrap .signup_content_area button.button_long{
	width: 100%;
	height: 4rem;
	background-color: #11167B;
	border-radius: 3.125rem;
	color: #FFFFFF;
	font-weight: 500;
	font-size: 1.25rem;
	margin-top: 3.125rem;
}
/* 회원가입 끝 */
/* 출석체크(전화번호) */
.content_wrap .attchk_mini_title{
	width: 100%;
	color: #878787;
	font-weight: 500;
	font-size: 1rem;
	margin-top: 4.375rem;
	margin-bottom: 1.25rem;
}
.content_wrap .display_num_area{
	display: flex;
	justify-content: space-between;
	width: 36.44rem;
	height: 4.188rem;
	border-bottom: #E3E4E8 1px solid;
	align-items: center;
}
.content_wrap .display_num_area .default_unit{
	width: 2.5rem;
	text-align: center;
	color: #B3B3B3;
	font-weight: 400;
	font-size: 3.5rem;
}
.content_wrap .display_num_area .blank{
	width: 2.813rem;
}
.content_wrap .button_unit{
	width: 9.688rem;
	height: 4.25rem;
	background-color: #FFFFFF;
	border-radius: 0.5rem;
	color: #A3A3A3;
	font-weight: 400;
	font-size: 2rem;
	text-align: center;
	line-height: 4.25rem;
	cursor: pointer;
}
.content_wrap .selected {
	background-color: #E2F1FF;
	color: #191919;
}
.content_wrap .button_del{
	width: 9.688rem;
	height: 4.25rem;
	background-color: #F4F4F4;
	border-radius: 0.5rem;
	color: #A2A2A2;
	font-weight: 400;
	font-size: 2rem;
	text-align: center;
	line-height: 4.25rem;
	cursor: pointer;
}
.content_wrap .del_selected {
	background-color: #ECECEC;
}
.content_wrap .save_complete{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 29.56rem;
	height: 16.63rem;
	background-color: #FFFFFF;
	border-radius: 0.625rem;
	text-align: center;
	z-index: 20; /* 슬라이딩 메뉴보다 아래에 배치 */
	border: #E4E4E4 solid 1px;
}
.content_wrap .overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8); /* 반투명한 흰색 배경 */
	z-index: 10; /* 슬라이딩 메뉴보다 아래에 배치 */
}
/* 출석체크(전화번호) 끝 */ 
/* 출석관리 */
.content_wrap .sliding_calendar {
	position: fixed;
	top: 16.88rem;
	left: calc(50% - 33.13rem);
	background-color: white;
	border: 1px solid #E5E8EB;
	z-index: 10;
}
.content_wrap .sliding_calendar.show {
	left: -25rem;
}
/* 출석관리 끝 */
/* 수강생관리 */
.content_wrap .btn_upside {
	display: flex;
	align-items: center;
	border-radius: 3.125rem;
	border: #E5E5E5 1px solid;
	background-color: #FFFFFF;
	cursor: pointer;
}
.content_wrap .btn_icon{
	width: 1.5rem;
	height: 1.5rem;
}
.content_wrap .btn_upside .btn_text{
	font-weight: 500;
	font-size: 0.88rem;
}
.content_wrap .btn_midside {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3.125rem;
	border: #E7E8E9 1px solid;
	height: 2rem;
	color: #8D8D8D;
	font-size: 0.81rem;
	font-weight: 400;
	margin-right: 0.63rem;
	cursor: pointer;
}
.content_wrap .btn_midside .btn_midicon {
	width: 0.31rem;
	height: 0.31rem;
	margin-right: 0.44rem;
}
.content_wrap .btn_moddel {
	width: 3.5rem;
	height: 2rem;
	border-radius: 0.5rem;
	background-color: #F8F8F8;
	font-weight: 400;
	font-size: 0.875rem;
	margin-left: 0.3125rem;
}
/* 수강생관리 끝 */
/* 수강생편집 */
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background: rgba(255, 255, 255, 0.8); */
	background: rgba(80, 80, 80, 0.4);
	display: flex;
	justify-content: center;
	align-items: center;
  }
.modal-content {
	display: flex;
	flex-direction: column; /* 세로 방향으로 정렬하도록 설정 */
	align-items: center; /* 가로 가운데 정렬 */
	border: #E4E4E4 1px solid;
	border-radius: 1.25rem;
	width: 37.5rem;
	height: 51.25rem;
	background-color: #FFFFFF;
}
.modal-content input {
	width: 27.5rem;
	height: 2.5rem;
	color: #B1B6C0;
	font-weight: 400;
	font-size: 1.125rem;
	border-bottom: #C1C1C1 1px solid;
	margin-bottom: 1.875rem;
}
.modal-content .unit_title {
	width: 27.5rem;
	font-weight: 600;
	font-size: 1.063rem;
	margin-bottom: 0.625rem;
}
.modal-content select {
	width: 11.5rem;
	height: 2.5rem;
	color: #B1B6C0;
	font-weight: 400;
	font-size: 1.125rem;
}
.modal-content img.button_img {
	width: 1.5rem;
	height: 1.5rem;
}
.modal-content .select_warp {
	display: flex;
	width: 100%;
	border-bottom: #C1C1C1 1px solid;
	align-items: center;
}
.modal-content .read_only {
	width: 27.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	color: #B1B6C0;
	font-weight: 400;
	font-size: 1.125rem;
	border-bottom: #C1C1C1 1px solid;
	margin-bottom: 1.875rem;
}
/* 수강생편집 끝 */
/* 일정편집 */
.modal-content .day_button {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 3.125rem;
	line-height: 2.5rem;
	text-align: center;
	font-weight: 400;
	font-size: 1rem;
	cursor: pointer;
}
.modal-content input.schedule {
	margin-bottom: 1.25rem;
}
.modal-content .read_only_sch {
	width: 27.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	color: #B1B6C0;
	font-weight: 400;
	font-size: 1.125rem;
	border-bottom: #C1C1C1 1px solid;
	margin-bottom: 1.25rem;
}
.modal-content .calendar_view {
	width: 100%;
	height: 2.5rem;
	border-bottom: #C1C1C1 1px solid;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
	font-size: 1rem;
	color: #B1B6C0;
}
.modal-overlay .sliding_calendar {
	position: fixed;
	top: 16.88rem;
	left: calc(50% - 33.13rem);
	background-color: white;
	border: 1px solid #E5E8EB;
	z-index: 10;
}
.modal-overlay .sliding_calendar.show {
	left: -25rem;
}
/* 일정편집 끝 */
/* 부재자관리 */
.content_wrap .title_absent {
	color: #191919;
	font-weight: 500;
	font-size: 1.375rem;
}
/* 부재자관리 끝 */
/* 태블릿&모바일 영역 */
header.tablet {
	display: none;
}
.content_wrap_tablet {
	display: none;
}
header.mobile {
	display: none;
}
.content_wrap_mobile {
	display: none;
}
.modal-content_tablet {
	display: none;
}
.modal-content_mobile {
	display: none;
}
.modal-overlay_tablet {
	display: none;
}
/* 태블릿&모바일 영역 끝 */
/* 팝업 */
.popup_select_wrap {
	display: flex;
	justify-content: space-between;
	border-bottom: #C1C1C1 1px solid;
	align-items: center;
	width: 15.63rem;
}
.popup_select_wrap img.pop_up_select {
	width: 1.5rem;
	height: 1.5rem;
}
.popup_attendinfo_wrap {
	/* width: 27.5rem; */
	/* height: 27.3125rem; */
	background: #FFF;
	border-radius: 1.25rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 3.13rem;
}
.popup_attendinfo_wrap .close_area {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.popup_attendinfo_wrap img.close {
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
}
.popup_attendinfo_wrap .username {
	color: #2D2D2D;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-right: 0.5rem;
}
.popup_attendinfo_wrap .seatno {
	display: inline-flex;
	height: 1.625rem;
	padding: 0.125rem 0.3125rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 0.25rem;
	background: #E5EFFF;
	color: #535353;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.popup_attendinfo_wrap .title {
	color: #0F5EFE;
	font-family: Pretendard;
	font-size: 1.0625rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 0.94rem;
}
.popup_attendinfo_wrap .timeinfo {
	display: flex;
	align-items: center;
	color: #454545;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.popup_attendinfo_wrap input {
	display: flex;
	width: 2.5rem;
	height: 2.125rem;
	text-align: center;
	border-radius: 0.375rem;
	background: #F8F9F9;
	color: #454545;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0rem 0.44rem;
}
.popup_attendinfo_wrap .timeunit {
	color: #A3A3A3;
	font-size: 1.1875rem;
}
.popup_attendinfo_wrap button.save {
	display: flex;
	width: 5rem;
	height: 3rem;
	justify-content: center;
	align-items: center;
	border-radius: 3.125rem;
	background: #11167B;
	color: #FFF;
	font-size: 1.0625rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.popup_specialsch_wrap {
	min-width: 27.5rem;
	/* height: 27.3125rem; */
	background: #FFF;
	border-radius: 1.25rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 3.13rem;
}
.popup_specialsch_wrap .close_area {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.popup_specialsch_wrap img.close {
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
}
.popup_specialsch_wrap .title {
	color: #373737;
	font-size: 1.0625rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	width: 100%;
	margin-top: 1rem;
}
.popup_specialsch_wrap input {
	display: flex;
	height: 2.5rem;
	padding: 0.5625rem 0rem;
	align-items: center;
	gap: 0.625rem;
	align-self: stretch;
	color: #B1B6C0;
	font-size: 1.1875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-bottom: 1px solid #E8E8E8;
}
.popup_specialsch_wrap button.save {
	display: flex;
	width: 5rem;
	height: 3rem;
	justify-content: center;
	align-items: center;
	border-radius: 3.125rem;
	background: #11167B;
	color: #FFF;
	font-size: 1.0625rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.popup_point_wrap {
	width: 27.5rem;
	height: 22.8125rem;
	border-radius: 1.25rem;
	background: #FFF;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 3.13rem;
}
.popup_point_wrap .username {
	color: #2D2D2D;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-right: 0.5rem;
}
.popup_point_wrap .seatno {
	display: inline-flex;
	height: 1.625rem;
	padding: 0.125rem 0.3125rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	flex-shrink: 0;
	border-radius: 0.25rem;
	background: #E5EFFF;
}
.popup_point_wrap .close_area {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.popup_point_wrap img.close {
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
}
.popup_point_wrap .subtitle {
	font-size: 1.0625rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.popup_point_wrap input {
	display: flex;
	width: 21.5rem;
	height: 2.5rem;
	padding: 0.5625rem 0rem;
	align-items: center;
	gap: 0.625rem;
	align-self: stretch;
	border-bottom: 1px solid #E8E8E8;
}
.popup_point_wrap button.save {
	display: flex;
	width: 5rem;
	height: 3rem;
	justify-content: center;
	align-items: center;
	border-radius: 3.125rem;
	background: #11167B;
	color: #FFF;
	font-size: 1.0625rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
/* 팝업 끝 */

/* ========== 미디어쿼리 빅타블렛======== */
@media (max-width: 1400px) {
	/* pc&모바일 영역 */
	header.desktop {
		display: none;
	}
	.content_wrap {
		display: none;
	}
	header.mobile {
		display: none;
	}
	.content_wrap_mobile {
		display: none;
	}
	.modal-content {
		display: none;
	}
	/* pc&모바일 영역 끝 */
	
	header.tablet {
		display: flex;
		flex-direction: column;
		align-items: center;
		/* width: 1100px; */
		width: 100%;
		height: 10rem;
		margin-left: auto;
		margin-right: auto;
		/* position: fixed; */
		z-index: 100;
		/* top:0; */
		background-color: #FFFFFF;
	}
	header.tablet button {
		color: #B6B6B6;
		font-weight: 400;
		font-size: 0.9375rem;
		margin-left: 1.25rem;
	}
	.content_wrap_tablet {
		/* width: 1100px; */
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
		align-items: center;
	}
	.content_wrap_tablet .sub_title {
		/* width: 1020px; */
		width: 95%;
		color: #191919;
		font-weight: 500;
		font-size: 1.25rem;
		margin-top: 1.25rem;
		align-items: center;
	}
	/* 로그인 */
	.content_wrap_tablet .login_content_area{
		width: 27.5rem;
		margin-top: 12.5rem;
		margin-bottom: 3.125rem;
	}
	.content_wrap_tablet .login_content_area .content_title{
		color: #000000;
		font-weight: 500;
		font-size: 1rem;
		margin-bottom: 1.563rem;
	}
	.content_wrap_tablet .login_content_area input{
		width: 100%;
		font-size: 1.125rem;
		margin-bottom: 1.875rem;
		padding-bottom: 0.625rem;
		border-bottom: #C1C1C1 1px solid; /* 초기에 사용할 색상 (예: 회색) */
		outline: none; /* 클릭 시 포커스 효과 제거 */
		transition: border-bottom-color 0.3s ease; /* 색상 변화에 대한 전환 효과 */
	}
	.content_wrap_tablet .login_content_area input:focus{
		border-bottom: #11167B 1.5px solid; /* 클릭 시 색상 변경 (예: 검은색) */
	}
	.content_wrap_tablet .login_content_area button{
		width: 100%;
		height: 4rem;
		background-color: #11167B;
		border-radius: 3.125rem;
		color: #FFFFFF;
		font-weight: 500;
		font-size: 1.25rem;
		margin-top: 3.125rem;
	}
	/* 로그인 끝 */
	/* 회원가입 */
	.content_wrap_tablet .signup_content_area{
		width: 27.5rem;
		margin-top: 3.125rem;
	}
	.content_wrap_tablet .signup_content_area .content_title{
		color: #000000;
		font-weight: 500;
		font-size: 1rem;
		margin-bottom: 1.563rem;
	}
	.content_wrap_tablet .signup_content_area .id_area{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 0.3125rem;
	}
	.content_wrap_tablet .signup_content_area .id_area input{
		width: 19.44rem;
		font-size: 1.125rem;
		padding-bottom: 0.625rem;
		border-bottom: #C1C1C1 1px solid; /* 초기에 사용할 색상 (예: 회색) */
		outline: none; /* 클릭 시 포커스 효과 제거 */
		transition: border-bottom-color 0.3s ease; /* 색상 변화에 대한 전환 효과 */
	}
	.content_wrap_tablet .signup_content_area .id_area button{
		width: 7.125rem;
		height: 4rem;
		background-color: #11167B;
		border-radius: 3.125rem;
		color: #FFFFFF;
		font-weight: 500;
		font-size: 1.25rem;
	}
	.content_wrap_tablet .signup_content_area .email_chk{
		color: #3AA0FF;
		font-weight: 400;
		font-size: 0.875rem;
		margin-bottom: 1.875rem;
	}
	.content_wrap_tablet .signup_content_area input.input_long{
		width: 100%;
		font-size: 1.125rem;
		margin-bottom: 1.875rem;
		padding-bottom: 0.625rem;
		border-bottom: #C1C1C1 1px solid; /* 초기에 사용할 색상 (예: 회색) */
		outline: none; /* 클릭 시 포커스 효과 제거 */
		transition: border-bottom-color 0.3s ease; /* 색상 변화에 대한 전환 효과 */
	}
	.content_wrap_tablet .signup_content_area input:focus{
		border-bottom: #11167B 1.5px solid; /* 클릭 시 색상 변경 (예: 검은색) */
	}
	.content_wrap_tablet .signup_content_area button.button_long{
		width: 100%;
		height: 4rem;
		background-color: #11167B;
		border-radius: 3.125rem;
		color: #FFFFFF;
		font-weight: 500;
		font-size: 1.25rem;
		margin-top: 3.125rem;
	}
	/* 회원가입 끝 */
	/* 출석체크(전화번호) */
	.modal-overlay_tablet {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.8); /* 회색 투명 배경 */
		display: flex;
		justify-content: center;
		align-items: center;
	  }
	.content_wrap_tablet .attchk_mini_title{
		width: 33.31rem;
		color: #878787;
		font-weight: 500;
		font-size: 1.125rem;
		margin-top: 4.375rem;
		margin-bottom: 1.25rem;
	}
	.content_wrap_tablet .display_num_area{
		display: flex;
		justify-content: space-between;
		width: 33.31rem;
		height: 4.188rem;
		border-bottom: #E3E4E8 1px solid;
		align-items: center;
	}
	.content_wrap_tablet .display_num_area .default_unit{
		width: 1.938rem;
		text-align: center;
		color: #B3B3B3;
		font-weight: 400;
		font-size: 3.125rem;
	}
	.content_wrap_tablet .display_num_area .blank{
		width: 1.938rem;;
	}
	.content_wrap_tablet .button_unit{
		width: 10.75rem;
		height: 4.688rem;
		background-color: #FFFFFF;
		border-radius: 0.5rem;
		color: #A3A3A3;
		font-weight: 400;
		font-size: 2.5rem;
		text-align: center;
		line-height: 4.688rem;;
		cursor: pointer;
	}
	.content_wrap_tablet .selected {
		background-color: #E2F1FF;
		color: #191919;
	}
	.content_wrap_tablet .button_del{
		width: 10.75rem;;
		height: 4.688rem;;
		background-color: #F4F4F4;
		border-radius: 0.5rem;
		color: #A2A2A2;
		font-weight: 400;
		font-size: 2.5rem;;
		text-align: center;
		line-height: 4.688rem;;
		cursor: pointer;
	}
	.content_wrap_tablet .del_selected {
		background-color: #ECECEC;
	}
	.content_wrap_tablet .save_complete{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 29.56rem;
		height: 16.63rem;
		background-color: #FFFFFF;
		border-radius: 0.625rem;
		text-align: center;
		z-index: 20; /* 슬라이딩 메뉴보다 아래에 배치 */
		border: #E4E4E4 solid 1px;
	}
	.content_wrap_tablet .overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.8); /* 반투명한 흰색 배경 */
		z-index: 10; /* 슬라이딩 메뉴보다 아래에 배치 */
	}
	/* 출석체크(전화번호) 끝 */ 
	/* 출석관리 */
	.content_wrap_tablet .sliding_calendar {
		position: fixed;
		top: 16.88rem;
		left: calc(50% - 33.13rem);
		background-color: white;
		border: 1px solid #E5E8EB;
		z-index: 10;
	}
	.content_wrap_tablet .sliding_calendar.show {
		left: -25rem;
	}
	/* 출석관리 끝 */
	/* 수강생관리 */
	.content_wrap_tablet .btn_upside {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3.125rem;
		border: #E5E5E5 1px solid;
		background-color: #FFFFFF;
		cursor: pointer;
	}
	.content_wrap_tablet .btn_icon{
		width: 1.5rem;
		height: 1.5rem;
	}
	.content_wrap_tablet .btn_upside .btn_text{
		font-weight: 500;
		font-size: 0.875rem;
	}
	.content_wrap_tablet .btn_midside {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3.125rem;
		border: #E7E8E9 1px solid;
		height: 2rem;
		color: #8D8D8D;
		font-size: 0.81rem;
		font-weight: 400;
		margin-right: 0.63rem;
		cursor: pointer;
	}
	.content_wrap_tablet .btn_midside .btn_midicon {
		width: 0.31rem;
		height: 0.31rem;
		margin-right: 0.44rem;
	}
	.content_wrap_tablet .btn_moddel {
		width: 3.5rem;
		height: 2rem;
		border-radius: 0.5rem;
		background-color: #F8F8F8;
		font-weight: 400;
		font-size: 0.875rem;
		margin-left: 0.3125rem;
	}
	/* 수강생관리 끝 */
	/* 부재자관리 */
	.content_wrap_tablet .title_absent {
		color: #191919;
		font-weight: 500;
		font-size: 1.25rem;
	}
	/* 부재자관리 끝 */
	/* 수강생편집 */
	.modal-content_tablet {
		display: flex;
		flex-direction: column; /* 세로 방향으로 정렬하도록 설정 */
		align-items: center; /* 가로 가운데 정렬 */
		border: #E4E4E4 1px solid;
		border-radius: 1.25rem;
		max-width: 37.5rem;
		width: 55%;
		max-height: 51.25rem;
		height: 90%;
		background-color: #FFFFFF;
		overflow-y: auto;
	}
	.modal-content_tablet input {
		width: 75%;
		height: 2.5rem;
		color: #B1B6C0;
		font-weight: 400;
		font-size: 1.125rem;
		border-bottom: #C1C1C1 1px solid;
		margin-bottom: 1.875rem;
	}
	.modal-content_tablet .unit_title {
		width: 75%;
		height: 2.5rem;
		line-height: 2.5rem;
		font-weight: 600;
		font-size: 1.063rem;
		margin-bottom: 0.3125rem;
	}
	.modal-content_tablet select {
		width: 80%;
		height: 2.5rem;
		color: #B1B6C0;
		font-weight: 400;
		font-size: 1.125rem;
	}
	.modal-content_tablet img.button_img {
		width: 1.5rem;
		height: 1.5rem;
	}
	.modal-content_tablet .select_warp {
		display: flex;
		width: 100%;
		border-bottom: #C1C1C1 1px solid;
		align-items: center;
	}
	.modal-content_tablet .read_only {
		width: 100%;
		height: 2.5rem;
		line-height: 2.5rem;
		color: #B1B6C0;
		font-weight: 400;
		font-size: 1.125rem;
		border-bottom: #C1C1C1 1px solid;
		margin-bottom: 1.875rem;
	}
	/* 수강생편집 끝 */
	/* 일정편집 */
	.modal-content_tablet .day_button {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 3.125rem;
		line-height: 2.5rem;
		text-align: center;
		font-weight: 400;
		font-size: 1rem;
		cursor: pointer;
	}
	.modal-content_tablet .read_only_sch {
		width: 100%;
		height: 2.5rem;
		line-height: 2.5rem;
		color: #B1B6C0;
		font-weight: 400;
		font-size: 1.125rem;
		border-bottom: #C1C1C1 1px solid;
		margin-bottom: 1.25rem;
	}
	.modal-content_tablet .calendar_view {
		width: 100%;
		height: 2.5rem;
		border-bottom: #C1C1C1 1px solid;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 500;
		font-size: 1rem;
		color: #B1B6C0;
	}
	/* 일정편집 끝 */
}
/* ========== 미디어쿼리 모바일======== */
@media (max-width: 880px) {
	/* pc&태블릿 영역 */
	header.desktop {
		display: none;
	}
	.content_wrap {
		display: none;
	}
	header.tablet {
		display: none;
	}
	.content_wrap_tablet {
		display: none;
	}
	header.mobile {
		display: flex;
		z-index: 100;
		background-color: #FFFFFF;
	}
	.header_area {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.5rem;
		font-weight: 600;
		padding: 1.31rem 1.25rem;
	}
	.modal-content_tablet {
		display: none;
	}
	/* pc&태블릿 영역 끝 */
	.content_wrap_mobile {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.content_wrap_mobile.padding_horizonal {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 0rem 1.25rem;
	}
	.content_wrap_mobile .sub_title {
		color: #191919;
		font-weight: 400;
		font-size: 2rem;
		margin: 3.13rem 0rem 0rem 0rem
	}
	.content_wrap_mobile .sub_title.attendchk {
		margin-left: 1.25rem;
	}
	.content_wrap_mobile .sub_title.studentmng {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.content_wrap_mobile .sub_title.attendmng {
		display: flex;
		align-items: center;
	}
	.content_wrap_mobile .sub_title.attendmng .notselected {
		color: #C1C1C1;
	}
	/* 홈 */
	.home_text_mobile {
		color: #373737;
		font-size: 2.25rem;
		font-weight: 500;
		text-align: center;
		margin-top: 5rem;
	}
	/* 홈 끝 */
	/* 로그인 */
	.content_wrap_mobile .login_content_area{
		padding: 8.37rem 1.25rem 0rem 1.25rem;
	}
	.content_wrap_mobile .login_content_area .content_title{
		color: #373737;
		font-weight: 500;
		font-size: 1.0625rem;
	}
	.content_wrap_mobile .login_content_area input{
		width: 100%;
		height: 2.5rem;
		font-size: 1.1875rem;
		font-weight: 400;
		margin-bottom: 2.5rem;
		padding: 0.5625rem 0rem;
		border-bottom: #C1C1C1 1px solid; /* 초기에 사용할 색상 (예: 회색) */
		outline: none; /* 클릭 시 포커스 효과 제거 */
		transition: border-bottom-color 0.3s ease; /* 색상 변화에 대한 전환 효과 */
	}
	.content_wrap_mobile .login_content_area input:focus{
		border-bottom: #11167B 1.5px solid; /* 클릭 시 색상 변경 (예: 검은색) */
	}
	.content_wrap_mobile .login_content_area button{
		width: 100%;
		height: 4rem;
		background-color: #11167B;
		border-radius: 3.125rem;
		color: #FFFFFF;
		font-weight: 500;
		font-size: 1.25rem;
		margin-top: 7.62rem;
	}
	/* 로그인 끝 */
	/* 출결관리 */
	.content_wrap_mobile .calendar_area{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 14.1875rem;
		height: 3rem;
		flex-shrink: 0;
		border-radius: 0.625rem;
		border: 1px #E0E0E0 solid;
		margin-top: 1.5rem;
		padding: 0rem 0.94rem;
	}
	.content_wrap_mobile .calendar_text{
		color: #0F5EFE;
		font-weight: 500;
		font-size: 1.375rem;
	}
	.content_wrap_mobile .sliding_calendar {
		position: fixed;
		background-color: white;
		border: 1px solid #E5E8EB;
		z-index: 10;
	}
	.content_wrap_mobile .sliding_calendar.show {
		left: -25rem;
	}
	.header_area_mobile_container {
		overflow-x: auto;
		width: 100%;
	}	
	.header_area_mobile {
		display: flex;
		color: #A6A7AA;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-top: 2.5rem;
		white-space: nowrap;
    	min-width: max-content;
	}
	.header_area_mobile .name {
		width: 4.69rem
	}
	.header_area_mobile .seat {
		width: 4.19rem
	}
	.header_area_mobile .enter {
		width: 10.44rem
	}
	.header_area_mobile .exit {
		width: 10.44rem
	}
	.header_area_mobile .phone {
		width: 9.69rem
	}
	.header_area_mobile .personalinfo {
		width: 12.63rem
	}
	.header_area_mobile .schedule {
		width: 15.56rem
	}
	.header_area_mobile .term {
		width: 10.25rem
	}
	.header_area_mobile .checkbox {
		width: 1.63rem;
	}
	.header_area_mobile .approve {
		width: 3.47rem;
	}
	.header_area_mobile .schedule_term {
		width: 14.31rem
	}
	.header_area_mobile .schedule_day {
		width: 9.31rem
	}
	.header_area_mobile .schedule_time {
		width: 9.47rem
	}
	.header_area_mobile .absentee {
		width: 4.25rem
	}
	.header_area_mobile .unauthabsentee {
		width: 6.25rem
	}
	.header_area_mobile .register_time {
		width: 11.34rem
	}
	.header_area_mobile .point {
		width: 4.31rem;
		color: #A6A7AA;
	}
	.header_area_mobile .comment {
		width: 7.69rem;
	}
	.header_area_mobile .subject {
		width: 3.75rem;
	}
	.header_area_mobile .studytime {
		width: 6.88rem;
	}
	.header_area_mobile .notice_code {
		width: 4.75rem;
	}
	.header_area_mobile .notice_replycnt {
		width: 3rem;
	}
	.header_area_mobile .notice_title {
		width: 8.87rem;
	}
	.header_area_mobile .notice_user {
		width: 4.69rem;
	}
	.header_area_mobile .notice_count {
		width: 3.69rem;
	}
	.header_area_mobile .notice_registdate {
		width: 5rem;
	}
	.header_area_mobile .message_title_mobile {
		width: 15rem;
	}
	.header_area_mobile .message_user_mobile {
		width: 4.5rem;
	}
	.header_area_mobile .message_count_mobile {
		width: 4.5rem;
	}
	.header_area_mobile .message_registdate_mobile {
		width: 6rem;
	}
	.body_area_mobile {
		display: inline-flex;
		height: 3.1875rem;
		align-items: center;
		flex-shrink: 0;
		border-bottom: 1px solid #EEE;
		background: #FFF;
		color: #454545;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		white-space: nowrap;
    	min-width: max-content;
	}
	.body_area_mobile .name {
		width: 4.69rem;
		color: #2D2D2D;
		font-weight: 500;
	}
	.body_area_mobile .seat {
		width: 4.19rem;
	}
	.body_area_mobile .seat .seatno{
		display: inline-flex;
		height: 1.5rem;
		padding: 0.125rem 0.3125rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		border-radius: 0.25rem;
		background: #E5EFFF;
		color: #535353;
		font-size: 1rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.body_area_mobile .enter {
		width: 10.44rem;
	}
	.body_area_mobile .exit {
		width: 10.44rem;
	}
	.body_area_mobile button.exit {
		display: inline-flex;
		height: 2.1875rem;
		padding: 0.25rem 0.5625rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 0.375rem;
		border: 1px solid #EFEFEF;
		background: #F8F8F8;
		color: #FF594F;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		width: auto;
    	min-width: fit-content;
	}
	.body_area_mobile .phone {
		width: 9.69rem
	}
	.body_area_mobile .personalinfo {
		width: 12.63rem
	}
	.body_area_mobile .schedule {
		width: 15.56rem
	}
	.body_area_mobile button.schedule {
		display: inline-flex;
		height: 2.1875rem;
		padding: 0.25rem 0.5625rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 0.375rem;
		border: 1px solid #EFEFEF;
		background: #F8F8F8;
		color: #FF594F;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		width: auto;
    	min-width: fit-content;
	}
	.body_area_mobile .term {
		width: 10.25rem
	}
	.body_area_mobile .approve {
		width: 3.47rem;
	}
	.body_area_mobile .schedule_term {
		width: 14.31rem;
		color: #0F5EFE;
	}
	.body_area_mobile .schedule_day {
		width: 9.31rem
	}
	.body_area_mobile .schedule_time {
		width: 9.47rem
	}
	.body_area_mobile .absentee {
		width: 4.25rem
	}
	.body_area_mobile .unauthabsentee {
		width: 6.25rem
	}
	.body_area_mobile button.unauthabsentee {
		display: inline-flex;
		height: 2.1875rem;
		padding: 0.25rem 0.5625rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 0.375rem;
		border: 1px solid #EFEFEF;
		background: #F8F8F8;
		color: #FF594F;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		width: auto;
    	min-width: fit-content;
	}
	.body_area_mobile .register_time {
		width: 11.34rem
	}
	.body_area_mobile .point {
		width: 4.31rem;
		color: #454545;
	}
	.body_area_mobile .point.plus {
		color: #0F5EFE;
	}
	.body_area_mobile .point.minus {
		color: #FF594F;
	}
	.body_area_mobile .comment {
		width: 7.69rem;
	}
	.body_area_mobile .subject {
		width: 3.75rem;
	}
	.body_area_mobile .studytime {
		width: 6.88rem;
	}
	.body_area_mobile .notice_code {
		width: 4.75rem;
	}
	.body_area_mobile .notice_replycnt {
		width: 3rem;
	}
	.body_area_mobile .notice_title {
		width: 8.87rem;
	}
	.body_area_mobile .notice_user {
		width: 4.69rem;
	}
	.body_area_mobile .notice_count {
		width: 3.69rem;
	}
	.body_area_mobile .notice_registdate {
		width: 5rem;
	}
	.body_area_mobile .message_title_mobile {
		width: 15rem;
	}
	.body_area_mobile .message_user_mobile {
		width: 4.5rem;
	}
	.body_area_mobile .message_count_mobile {
		width: 4.5rem;
	}
	.body_area_mobile .message_registdate_mobile {
		width: 9rem;
	}
	.etc_area {
		display: flex;
		width: 9rem;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.0625rem;
		box-shadow: 0px 10px 30px 0px rgba(38, 38, 38, 0.15);
		border-radius: 0rem 0rem 0.5rem 0.5rem;
	}
	.etc_area button.del{
		display: flex;
		height: 2.75rem;
		padding: 0.625rem;
		align-items: center;
		gap: 0.25rem;
		align-self: stretch;
		background: #FFF;
	}
	.etc_area button.del.mod{
		border-bottom: 1px solid #DDD;
	}
	button.more{
		display: inline-flex;
		height: 3rem;
		padding: 0.875rem 1.5625rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		flex-shrink: 0;
		border-radius: 3.125rem;
		background: #11167B;
		box-shadow: 0px 10px 30px 0px rgba(38, 38, 38, 0.15);
		color: #FFF;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.text_mod{
		color: #454545;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.text_mod.plus{
		color: #0F5EFE;
	}
	.text_mod.del{
		color: #FF594F;
	}
	.content_wrap_mobile .btn_midside {
		display: flex;
		width: 4.5rem;
		height: 2.5rem;
		padding: 0.4375rem;
		justify-content: center;
		align-items: center;
		gap: 0.2rem;
		color: #68686B;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		border-radius: 0.5rem;
		border: 1px solid #F3F3F3;
		white-space: nowrap;
	}
	.make_attend_area {
		display: flex;
		width: 9.75rem;
		height: 2.5rem;
		padding: 0.625rem 1.125rem;
		align-items: center;
		gap: 0.625rem;
		border-radius: 3.125rem;
		border: 1px solid #E5E5E5;
		background: #F8F8F8;
	}
	.make_attend_area input{
		width: 4.3125rem;
		color: #A6A7AB;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		background: transparent;
	}
	.make_attend_area button{
		display: flex;
		width: 3.5rem;
		height: 2.5rem;
		padding: 0.6875rem 0.8125rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		flex-shrink: 0;
		border-radius: 31.25rem;
		background: #11167B;
		color: #FFF;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	/* 출결관리 끝 */
	/* 출석체크 */
	.modal-overlay_tablet {
		display: none;
	}
	.attchk_mini_title {
		color: #878787;
		font-weight: 500;
		font-size: 1.375rem;
	}
	.display_num_area {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 1.5rem;
		width: 100%;
	}
	.default_unit {
		color: #C8C9CC;
		font-size: 2.625rem;
		font-weight: 400;
		margin-right: 0.38rem;
	}
	.blank {
		width: 1.5rem;
	}
	.button_unit{
		width: 8.625rem;
		height: 4.5rem;
		background-color: #FFFFFF;
		border-radius: 0.5rem;
		color: #A3A3A3;
		font-weight: 400;
		font-size: 2rem;
		text-align: center;
		line-height: 4.5rem;
		cursor: pointer;
	}
	.button_unit.selected {
		background-color: #E2F1FF;
		color: #191919;
	}
	.button_del{
		width: 8.625rem;;
		height: 4.5rem;;
		background-color: #F4F4F4;
		border-radius: 0.5rem;
		color: #A2A2A2;
		font-weight: 400;
		font-size: 1.875rem;;
		text-align: center;
		line-height: 4.5rem;;
		cursor: pointer;
	}
	.button_del.del_selected {
		background-color: #ECECEC;
	}
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.8); /* 반투명한 흰색 배경 */
		z-index: 10; /* 슬라이딩 메뉴보다 아래에 배치 */
	}
	.save_complete{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 29.56rem;
		height: 16.63rem;
		background-color: #FFFFFF;
		border-radius: 0.625rem;
		text-align: center;
		z-index: 20; /* 슬라이딩 메뉴보다 아래에 배치 */
		border: #E4E4E4 solid 1px;
	}
	/* 출석체크 끝 */
	/* 모바일메뉴 */
	.mobile_menu_area {
		border-top: #E3E4E8 0.0625rem solid;
		padding: 1.25rem 1.19rem;
		color: #191919;
		font-weight: 400;
		font-size: 1.375rem;
	}
	.mobile_menu_area.logout {
		color: #A3A3A3;
		border-bottom: #E3E4E8 0.0625rem solid;
		font-size: 1.25rem;
	}
	/* 모바일메뉴 끝 */
	/* 수강생관리 */
	button.add_student {
		display: flex;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0.625rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		flex-shrink: 0;	
		border-radius: 1.375rem;
		border: 1px solid #E5E5E5;
	}
	button.add_student img {
		width: 1.875rem;
		height: 1.875rem;
		flex-shrink: 0;
	}
	.button_season_sort {
		display: flex;
		width: 4.5rem;
		height: 2.5rem;
		padding: 0.4375rem 0.375rem;
		/* flex-direction: column; */
		justify-content: center;
		align-items: center;
		/* gap: 0.625rem; */
		border-radius: 0.5rem;
		border: 1px solid #F3F3F3;
		color: #68686B;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	select.season {
		color: #7962F6;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		width: 2.3rem;
	}
	.search_name_area {
		display: flex;
		width: 9.75rem;
		height: 2.5rem;
		padding: 0.625rem 0rem;
		align-items: center;
		justify-content: space-between;
		gap: 0.625rem;
		flex-shrink: 0;
		border-radius: 3.125rem;
		border: 1px solid #E5E5E5;
		background: #F8F8F8;
	}
	.search_name_area input {
		margin-left: 1rem;
		width: 4.5rem;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		background: transparent;
	}
	.search_name_area .button_area {
		display: flex;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0.6875rem 0.8125rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		flex-shrink: 0;
		border-radius: 31.25rem;
		background: #11167B;
	}
	button.manage_area {
		display: flex;
		height: 2.5rem;
		padding: 0.375rem 0.75rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		border-radius: 3.125rem;
		background: #EEF4FF;
		margin-right: 0.5rem;
	}
	button.manage_area.approve {
		border: 1.2px #0F5EFE solid;
		color: #0F5EFE;
	}
	button.manage_area.approve_cancel {
		border: 1.2px #C4C4C4 solid;
		color: #C4C4C4;
	}
	button.manage_area.del {
		border: 1.2px #FF594F solid;
		color: #FF594F;
	}
	button.manage_area.except {
		border: 1.2px #7962F6 solid;
		color: #7962F6;
	}
	/* 수강생관리 끝 */
	/* 수강생편집 */
	.modal-content_mobile {
		display: flex;
		flex-direction: column;
		width: 27.5rem;
		height: 46.6875rem;
		border-radius: 1.25rem;
		background: #FFF;
	}
	.modal-content_mobile.newmodspecialsch {
		height: 35.6875rem;
	}
	.modal-content_mobile.newmodschedule {
		height: 47.4375rem;
	}
	.modal-content_mobile .title {
		color: #2D2D2D;
		font-size: 2.25rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-bottom: 3rem;
	}
	.modal-content_mobile .subtitle {
		color: #373737;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.modal-content_mobile .subtitle.half {
		width: 10rem;
	}
	.modal-content_mobile .read_only {
		display: flex;
		height: 2.5rem;
		padding: 0.5625rem 0rem;
		align-items: center;
		gap: 0.625rem;
		align-self: stretch;
		border-bottom: 1px solid #E8E8E8;
		color: #373737;
		font-size: 1.1875rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.modal-content_mobile input {
		display: flex;
		width: 100%;
		height: 2.5rem;
		padding: 0.5625rem 0rem;
		align-items: center;
		gap: 0.625rem;
		align-self: stretch;
		border-bottom: 1px solid #E8E8E8;
		font-size: 1.1875rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.modal-content_mobile .select_warp {
		display: flex;
		width: 10rem;
		height: 2.5rem;
		padding: 0.5625rem 0rem;
		align-items: center;
		justify-content: space-between;
		gap: 0.625rem;
		align-self: stretch;
		border-bottom: 1px solid #E8E8E8;
	}
	.modal-content_mobile .select_warp.half {
		width: 4.5rem;
	}
	.modal-content_mobile .select_warp img {
		width: 1.25rem;
		height: 1.25rem;
		flex-shrink: 0;
	}
	.modal-content_mobile button.complete{
		display: flex;
		width: 5rem;
		height: 3rem;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		border-radius: 3.125rem;
		background: #11167B;
		color: #FFF;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.modal-content_mobile .day_select{
		display: flex;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0.5rem 0.75rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		border-radius: 1.25rem;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	/* 수강생편집 끝 */
	/* 부재자관리 */
	.timeinfo_area {
		display: inline-flex;
		width: fit-content;
		height: 3rem;
		padding: 0.75rem 0.8125rem;
		align-items: center;
		border-radius: 0.625rem;
		border: 1px solid #EEE;
		margin-top: 1.5rem;
		color: #0F5EFE;
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	/* 부재자관리 끝 */
	/* 게시판 */
	.notice_content {
		margin-top: 1.5rem;
	}
	.notice_content.notice_read {
		margin-top: 2.12rem;
		color: #595959;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.4375rem; /* 153.333% */
	}
	.notice_content.write_reply {
		margin-top: 0rem;
	}
	.notice_content input{
		border: 1px solid #E8E8E8;
		border-radius: 0.25rem;
		padding: 0.5rem 1rem;
		width: 100%;
		font-size: 1.1875rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.notice_content textarea{
		display: flex;
		width: 100%;
		height: 15rem;
		padding: 1rem 0.75rem 1rem 1.25rem;
		align-items: flex-start;
		gap: 0.625rem;
		flex-shrink: 0;
		border-radius: 0.625rem;
		border: 1px solid #E8E8E8;
		color: #707070;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.125rem; /* 112.5% */
		resize: none;
	}
	.notice_content textarea.reply{
		height: 5rem;
		margin-top: 0.75rem;
	}
	.notice_content .title {
		font-size: 1.6rem;
		font-weight: 600;
		margin-bottom: 2rem;
	}
	.notice_subtitle {
		color: #373737;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-right: 1rem;
	}
	.notice_subtitle.alert{
		color: #FF594F;
	}
	.notice_checkbox {
		color: #373737;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-right: 1rem;
	}
	.notice_select_file {
		display: flex;
		height: 2.1875rem;
		padding: 0.25rem 0.5625rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		border-radius: 0.375rem;
		border: 1px solid #EFEFEF;
		background: #F8F8F8;
		color: #535353;
		font-family: Pretendard;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.notice_filelist {
		margin-top: 0.75rem;
		display: inline-flex;
		height: 4.375rem;
		padding: 0rem 1.25rem 0rem 1.1875rem;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
		border-radius: 0.625rem;
		border: 1px solid #E3E3E7;
		color: #686868;
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.notice_filelist img {
		width: 1.125rem;
		height: 1.125rem;
	}
	button.notice_del {
		display: inline-flex;
		padding: 0.25rem 0.5625rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		color: #FFF;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		border-radius: 0.375rem;
		border: 1px solid #EFEFEF;
		background: #FF594F;
		margin-left: 1rem;
	}
	button.notice_save {
		display: flex;
		width: 4.5rem;
		height: 2.25rem;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		border-radius: 0.375rem;
		background: #11167B;
		color: #FFF;
		font-size: 1rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	button.notice_save.back {
		background: #F4F4F4;
		color: #A2A2A2;
	}
	button.notice_save.del {
		background: #F4F4F4;
		color: #FF594F;
	}
	button.notice_save.reply_save {
		width: 3.75rem;
		height: 2.5rem;
		border-radius: 0.5rem;
	}
	.notice_countinfo {
		display: inline-flex;
		padding: 0.1rem 0.2rem;
		background: #FFF;
		border: 1px #535353 solid
	}
	.notice_read_info {
		display: flex;
		color: #B1B6C0;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-top: 0.5rem;
	}
	.notice_read_subtitle {
		color: #8C8C8C;
		font-size: 1rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-top: 2rem;
	}
	.notice_read_subtitle.replycount {
		color: #0F5EFE;
		margin-left: 0.3rem;
	}
	.notice_reply_area {
		display: flex;
		flex-direction: column;
		padding: 1rem 1.25rem;
		justify-content: center;
		gap: 0.625rem;
		border-radius: 0.625rem;
		background: #F2F2F6;
		margin-top: 0.75rem;
	}
	.notice_reply_area .text {
		color: #8D8D8D;
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-right: 0.62rem;
	}
	.notice_reply_area .text.username {
		color: #2C2D2D;
	}
	.notice_reply_area .regdate {
		color: #B7B8BD;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.notice_reply_area img {
		width: 1rem;
		height: 1rem;
	}
	.notice_reply_area .reply {
		color: #707070;
		font-family: Pretendard;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.375rem; /* 137.5% */
	}
	/* 게시판 끝 */
	.content_wrap_mobile .no_provide {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 30rem;
		width: 90%;
		max-height: 16.63rem;
		height: 35%;
		background-color: #FFFFFF;
		border-radius: 0.625rem;
		text-align: center;
		border: #E4E4E4 solid 1px;
		font-weight: 500;
		font-size: 1.188rem;
	}
}

/* 리뉴얼 css */
.btn_0dot75rem {
	width: 0.75rem;
	height: 0.75rem;
}
.btn_0dot875rem {
	width: 0.875rem;
	height: 0.875rem;
}
.btn_1rem {
	width: 1rem;
	height: 1rem;
}
.btn_1dot5rem {
	width: 1.5rem;
	height: 1.5rem;
}
.flexcenter {
	display: flex;
	align-items: center;
}
.content_area {
	max-width: 87.5rem;
	margin-left: auto;
	margin-right: auto;
}
.menutitlebox {
	margin-top: 1.25rem;
	color: #191919;
	font-weight: 500;
	font-size: 1.38rem;
	margin-bottom: 4.75rem;
}
.mainbuttonarea {
	width: 100%;
	margin-top: 13.5rem;
	margin-bottom: 7.75rem;
	display: flex;
	justify-content: center;
}
.mainbutton {
	display: flex;
	width: 5rem;
	height: 2.5rem;
	padding: 0.625rem 1rem;
	justify-content: center;
	align-items: center;
	border-radius: 0.5rem;
	background: #11167B;
	color: #FFF;
	text-align: center;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
/* 메시지 발송 */
.message_write_menutitle {
	color: #8A8A8A;
	font-size: 0.9375rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem; /* 160% */
	margin-bottom: 0.5rem;
}
.message_write_input {
	height: 2rem;
	width: 50rem;
	padding: 0rem 0.75rem;
	border-radius: 0.375rem;
	border: 1px solid #E2E2E2;
	background: var(--white, #FFF);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 1.5rem;
}
.message_write_textarea {
	height: 11.5625rem;
	width: 50rem;
	padding: 0.75rem;
	resize: none;
	border-radius: 0.375rem;
	border: 1px solid #E2E2E2;
	background: var(--white, #FFF);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 1.5rem;
}
.message_write_button {
	height: 1.625rem;
	padding: 0rem 0.625rem;
	border-radius: 0.375rem;
	border: 1px solid #E2E2E2;
	color: #0F79FF;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
	margin-left: 0.5rem;
}
.message_write_receiver_list {
	width: 50rem;
	display: flex;
	flex-wrap: wrap;
	gap: 0.75rem;
	padding: 1rem;
	border-radius: 0.375rem;
	background: #F6F6F6;
	margin-top: 0.5rem;
}
.message_write_receiver_body {
	color: #191919;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.message_modal_background {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5);
	backdrop-filter: blur(5px);
	z-index: 999;
}
.message_modal_container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 56.125rem;
	max-width: 90%; /* 작은 화면에서 너무 넓어지지 않도록 제한 */
	padding: 3.5rem;
	background-color: #FFF;
	border-radius: 1.25rem;
	box-shadow: 4px 2px 12px 1px rgba(0, 0, 0, 0.06);
	z-index: 1000;
}
.message_title_area {
	justify-content: space-between;
	margin-bottom: 2rem;
}
.message_btn_close {
	cursor: pointer;
}
.message_title {
	color: #191919;
	font-size: 1.625rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.message_btn_group {
	margin-bottom: 1rem;
}
.message_btn_sort_wrap {
	width: fit-content;
	height: 2rem;
	padding: 0.375rem 0.625rem;
	gap: 0.25rem;
	border-radius: 3.125rem;
	border: 1px solid #E7E8E9;
	cursor: pointer;
	margin-right: 0.5rem;
}
.message_btn_sort_text {
	color: #8D8D8D;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-left: 0.25rem;
}
.message_search_wrap {
	width: 8.0625rem;
	height: 2rem;
	padding: 0.625rem 0.9375rem;
	gap: 0.625rem;
	border-radius: 3.125rem;
	border: 1px solid #E7E8E9;
	background: #F8F8F8;
	justify-content: space-between;
}
.message_search_icon_wrap {
	width: 2rem;
	height: 2rem;
	justify-content: center;
	flex-shrink: 0;
	border-radius: 624.9375rem;
	background: #11167B;
}
.message_search_icon {
	width: 0.9375rem;
	height: 0.99613rem;
	flex-shrink: 0;	
}
.message_search_input {
	border: none;
    background: transparent;
    outline: none;
    flex-grow: 1;
    margin-right: 0.625rem;
	width: 4rem; /* 검색 아이콘 너비를 고려하여 조정 */
}
.message_people_list_wrap {
	justify-content: space-between;
}
.message_people_list {
	display: flex;
	width: 20rem;
	height: 31.875rem;
	padding: 0.5rem 0.375rem;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 0.625rem;
	border: 1px solid #E2E2E2;
}
.message_people_list_header_wrap {
	display: flex;
	padding: 0.5rem 1rem;
	align-items: center;
	gap: 0.625rem;
	align-self: stretch;
	border-radius: 0.375rem;
	background: #F8F8F8;
}
.message_people_list_header_text {
	color: #11167B;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.message_people_list_control_wrap {
	display: flex;
	min-height: 2rem;
	padding: 0rem 1rem;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
	border-bottom: 1px solid #F2F2F2;
}
.message_people_list_control_field {
	color: #8D8D8D;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.control_field_name {
	width: 7.3rem;
}
.message_people_list_body_wrap {
	display: flex;
	min-height: 1.75rem;
	padding: 0rem 1rem;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
}
.message_people_list_body_name {
	color: #191919;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	width: 7.3rem;
}
.message_parenttype {
	display: flex;
	height: 1.125rem;
	padding: 0rem 0.25rem;
	align-items: center;
	gap: 0.375rem;
	border-radius: 0.3125rem;
	background: var(--gray-200, #E2E8F0);
	margin-left: 0.25rem;
	color: #768294;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1rem; /* 133.333% */
}
.message_people_list_body_seatno {
	height: 1.125rem;
	padding: 0rem 0.25rem;
	gap: 0.375rem;
	border-radius: 0.3125rem;
	background: #E5EFFF;
	color: #535353;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1rem; /* 133.333% */
}
.message_scroll_y {
	flex-grow: 1;
    overflow-y: auto;
    width: 100%;
}
/* 메시지 발송 */
/* 부재자 관리 */
.absentee_modal_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	justify-content: center;
	align-items: center;
}
.absentee_modal_content {
	display: flex;
	width: 30.1875rem;
	padding: 3.5rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 2rem;
	border-radius: 1.25rem;
	background: #FFF;
	box-shadow: 4px 2px 12px 1px rgba(0, 0, 0, 0.06);
}
.absentee_btn_modal {
	display: flex;
	padding: 0.625rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 0.375rem;
	border: 1px solid #E9E9E9;
	color: #414141;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;
}
.absentee_modal_stdname {
	color: #191919;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.absentee_modal_seatno {
	margin-left: 0.25rem;
	display: flex;
	padding: 0.0625rem 0.25rem;
	align-items: center;
	gap: 0.375rem;
	border-radius: 0.3125rem;
	background: #E5EFFF;
	color: #535353;
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.625rem; /* 118.182% */
}
.absentee_modal_status {
	color: #000;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 2rem;
}
.absentee_modal_select {
	position: absolute;
	width: 5rem;
	height: 15rem;
	top: 50%;
	left: 50%;
	transform: translate(-11.5rem, 5.5rem);
	overflow-y: auto;
	border-radius: 0.375rem;
	background-color: #000;
	color: #FFF;
	font-size: 1.3rem;
	font-style: normal;
	font-weight: 500;
	padding: 0.5rem;
	padding-left: 1rem;
}
.absentee_floor_sel {
	color: #191919;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.absentee_color_info {
	width: 1rem;
	height: 1rem;
	flex-shrink: 0;
	aspect-ratio: 1/1;
	border-radius: 0.125rem;
	margin-right: 0.44rem;
}
.absentee_color_info_text {
	color: #8A8A8A;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}